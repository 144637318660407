import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { HttpService } from 'src/app/shared/services/http.service';
import { PayrollDateRange, Options } from 'src/app/interfaces/payroll/payroll-interfaces';
import * as moment from 'moment-timezone';

@Component({
  selector: 'weekly-calendar',
  templateUrl: './weekly-calendar.component.html',
  styleUrls: ['./weekly-calendar.component.scss']
})
export class WeeklyCalendarComponent implements OnInit {

  @Output() onChangeDate: EventEmitter<PayrollDateRange> = new EventEmitter<PayrollDateRange>();

  @Input() isPrepayroll: boolean = false;
  @Input() options: Options = { id: null, type: null };

  public selectedDate: PayrollDateRange = { 
    startDate: moment().startOf('week'), 
    endDate: moment().endOf('week'), 
    year: this.validYear(moment().startOf('week'),moment().endOf('week')), 
    week: moment().week() 
  };
  public maxDate: moment.Moment = moment().add(10, 'w');
  public locale: object = datePicker.locale.spanish;

  constructor(
    private httpService: HttpService
  ) { }

  ngOnInit(): void {     
    this.onChangeDate.emit(this.selectedDate);

    if (this.options?.type == "EDIT") {
      this.loadEditCalendar();
    }
  
  }

  public getWeekNumber = (date: moment.Moment): number => { return moment(date).week(); }

  public loadEditCalendar = async(): Promise<void> => {

    try{

      let data = await this.httpService.get(`api/${this.isPrepayroll ? "prepayroll" : "payroll"}/${this.options.id}`).toPromise();
      
      this.selectedDate = { 
        startDate: moment().week(data.week).year(data.year).startOf("week"), 
        endDate: moment().week(data.week).year(data.year).endOf('week'),
        year: data.year,
        week: data.week
      };
      this.onChangeDate.emit(this.selectedDate);
      
    }
    catch(error){
      console.log(`Error al cargar el calendario de la ${ this.isPrepayroll ? "Pre-nomina" : "Nomina" }`, error);
    }
  }

  public selectDate = (event: PayrollDateRange): void => {

    if (event.startDate) {
      this.selectedDate = { 
        startDate: event.startDate.startOf('week'), 
        endDate: event.endDate.endOf('week'), 
        year: this.validYear(event.startDate.startOf('week'), event.endDate.endOf('week')), 
        week: event.startDate.week() 
      };
      
      this.onChangeDate.emit(this.selectedDate);
    }
  
  }

  public validYear(startDate,endDate){
      // Se valida el año de las dos fechas .
      let startYear = parseInt(startDate.format("YYYY"));
      let endYear = parseInt(endDate.format("YYYY"));
      let year = startYear > endYear ? startYear : endYear;
      return year;
  }

}