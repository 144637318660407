import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { PayrollDateRange, Options } from 'src/app/interfaces/payroll/payroll-interfaces';
import { UserService } from 'src/app/shared/services/user.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'dynamic-calendar',
  templateUrl: './dynamic-calendar.component.html',
  styleUrls: ['./dynamic-calendar.component.scss']
})
export class DynamicCalendarComponent implements OnInit, OnChanges {

  @Output() onChangeDate: EventEmitter<PayrollDateRange> = new EventEmitter<PayrollDateRange>();

  @Input() isPrepayroll: boolean = false;
  @Input() options: Options = {id: null, type: null};
  @Input() isSpecial: boolean = false;
  @Input() showLabel: boolean = false;
  @Input() dates: PayrollDateRange = { startDate: null, endDate: null };

  public selectedDate: PayrollDateRange = { startDate: null, endDate: null };
  public maxDate: moment.Moment = moment().add(20, 'w');
  public periodOfYear: number = 1;
  public typeOfPeriod: string;
  public locale: object = datePicker.locale.spanish;

  constructor(
    private userService: UserService
  ) { 
    
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.typeOfPeriod = this.options?.calendarType || this.userService?.user?.calendarType;
    if (this.options?.type == "EDIT") this.getPeriod(this.dates?.startDate);
    else this.getPeriod(moment());
  }

  ngOnInit(): void {

  }

  public getPeriod = (date: moment.Moment): void => {

    let periodStart: moment.Moment;
    let periodEnd: moment.Moment;
    let periodCount: number = 1;

    switch (this.typeOfPeriod) {
      case "CATORCENAL": {

        periodStart = date.clone().startOf('week').week(1);
        periodEnd = periodStart.clone().add(13, 'days');

        while((date.isBefore(periodStart) || date.isAfter(periodEnd))){
          periodStart.add(14, 'days');
          periodEnd.add(14, 'days');
          periodCount++;
        }
        
        this.periodOfYear = periodCount;
        this.selectedDate = { startDate: periodStart, endDate: periodEnd, week: this.periodOfYear, year:  parseInt(periodStart.format("YYYY")) };

        this.onChangeDate.emit(this.selectedDate);
        break;

      }
      case "SEMANAL": {
   
        periodStart = date.clone().startOf('week');
        periodEnd = date.clone().endOf('week');
        this.periodOfYear = date.week();

        this.selectedDate = { startDate: periodStart, endDate: periodEnd, week: this.periodOfYear, year: this.validYear(periodStart,periodEnd) };
           
        this.onChangeDate.emit(this.selectedDate);
        break;

      }
      case "QUINCENAL": {

        let monthFifteenthDay = date.clone().date(15);
        let periodCount = date.clone().month() + 1;

        if(date.isAfter(monthFifteenthDay)){
          periodStart = date.clone().date(16);
          periodEnd = periodStart.clone().endOf('month');
          this.periodOfYear = periodCount * 2;
        }
        else {
          periodStart = date.clone().startOf('month');
          periodEnd = monthFifteenthDay.clone();
          this.periodOfYear = (periodCount * 2) - 1;
        }

        this.selectedDate = { startDate: periodStart, endDate: periodEnd, week: this.periodOfYear, year:  parseInt(periodStart.format("YYYY"))};
        this.onChangeDate.emit(this.selectedDate);

        break;

      }
      case "MENSUAL": {
        periodStart = date.clone().startOf('month');
        periodEnd = date.clone().endOf('month');
        this.periodOfYear = date.month() + 1;
        this.selectedDate = { startDate: periodStart, endDate: periodEnd, week: this.periodOfYear, year:  parseInt(periodStart.format("YYYY")) };

        this.onChangeDate.emit(this.selectedDate);
        break;
      }
      case "VARIABLE": {

        periodStart = date.clone().startOf('week').week(1);
        periodEnd = periodStart.clone().add(13, 'days');

        while((date.isBefore(periodStart) || date.isAfter(periodEnd))){
          periodStart.add(14, 'days');
          periodEnd.add(14, 'days');
          periodCount++;
        }

        this.selectedDate = { startDate: periodStart, endDate: periodEnd };
        this.periodOfYear = periodCount;        

        this.onChangeDate.emit(this.selectedDate);

        break;

      }
      default: {
        break;
      }
    }

  }

  selectDate(event: any) {
    if (event.startDate) {
      this.getPeriod(event.startDate);
    }
  }

  public validYear(startDate,endDate){
    // Se valida el año de las dos fechas .
    let startYear = parseInt(startDate.format("YYYY"));
    let endYear = parseInt(endDate.format("YYYY"));
    let year = startYear > endYear ? startYear : endYear;
    return year;
}

}